const productConfig = {
    components: [
        {
            type: "v1_code",
            version: "1",
            config: {},
        },
        {
            type: "v2_code",
            version: "2",
            config: {},
        },
        {
            type: "v2_image",
            version: "2",
            config: {},
        },
        {
            type: "v1_image360",
            version: "1",
            config: {},
        },
        {
            type: "v2_image360",
            version: "2",
            config: {},
        },
        {
            type: "v1_schedule",
            version: "1",
            config: {},
        },
        {
            type: "v2_schedule",
            version: "2",
            config: {},
        },
        {
            type: "v1_table",
            version: "1",
            config: {},
        },
        {
            type: "v2_table",
            version: "2",
            config: {},
        },
        {
            type: "v1_oglink",
            version: "1",
            config: {},
        },
        {
            type: "v2_oglink",
            version: "2",
            config: {},
        },
        {
            type: "v1_video",
            version: "1",
            config: {},
        },
        {
            type: "v2_video",
            version: "2",
            config: {},
        },
        {
            type: "v1_map",
            version: "1",
            config: {},
        },
        {
            type: "v2_map",
            version: "2",
            config: {
                isDynamicMap: true,
                showBookmarkBtn: true,
                showBookingBtn: true,
                showDialBtn: true,
            },
        },
        {
            type: "v1_file",
            version: "1",
            config: {},
        },
        {
            type: "v2_file",
            version: "2",
            config: {},
        },
        {
            type: "v1_material_shopping",
            version: "1",
            config: {},
        },
        {
            type: "v2_material_shopping",
            version: "2",
            config: {},
        },
        {
            type: "v1_material_news",
            version: "1",
            config: {},
        },
        {
            type: "v2_material_news",
            version: "2",
            config: {},
        },
        {
            type: "v2_formula",
            version: "2",
            config: {},
        },
        {
            type: "v2_imageGroup",
            version: "2",
            config: {},
        },
        {
            type: "v2_oembed",
            version: "2",
            config: {},
        },
        {
            type: "v2_audio",
            version: "2",
            config: {},
        },
        {
            type: "v2_webtoon",
            version: "2",
            config: {},
        },
        {
            type: "v2_accordion",
            version: "2",
            config: {},
        },
    ],
};
export default productConfig;
