/**
 * @class se.module.v1.VR360.Navigation
 * @author woo jaewoo
 * @since 2016.10.12
 * Photo360Viewer + se.module.v1.VR360.Navigation
 */

import {VR360Config} from "./vr360-config";

export class VR360Navigation {
    constructor(element, state) {
        this.$nav = $seJq(element);

        this.state = {
            direction: state.direction || 0,
            fov: state.fov || 65,
        };

        const horizontalAngleOfImage = (state.horizontalAngleOfImage || 360) / 360;
        this.NAVIGATION_FRAME = this.getNavigation(horizontalAngleOfImage);
    }

    init() {
        this.render(this.state.direction, this.state.fov);
        this.setState(this.state.direction / -1, this.state.fov);
    }
    resetState() {
        this.state = {
            direction: 0,
            fov: 65,
        };

        this.render(this.state.direction, this.state.fov);
    }
    setState(direction, fov) {
        this.state.direction = direction || this.state.direction;
        this.state.fov = fov || this.state.fov;

        this.render(this.state.direction, this.state.fov);
    }
    appendNavigation() {
        const $wrapper = $seJq('<div class="se-360vr-viewing-angle"></div>');

        $wrapper.append(this.NAVIGATION_FRAME).append(VR360Config.VIEWER.NAVIGATION.HTML.PIE);

        this.$nav.append($wrapper);

        const navSize = VR360Config.VIEWER.NAVIGATION.SIZE;
        this.$pie = this.$nav.find(".__se_360vr_navPie").css({position: "absolute", top: 0, left: 0});
        this.$piePath = this.$pie.find("path").attr("transform", "translate(" + navSize / 2 + ", " + navSize / 2 + ")");
    }
    render(direction, fov) {
        const radius = VR360Config.VIEWER.NAVIGATION.PIE_RADIUS;
        const r = (fov * Math.PI) / 180;
        const x = Math.sin(r) * radius;
        const y = Math.cos(r) * radius * -1;
        const mid = fov > 180 ? 1 : 0;
        const anim = "M 0 0 v -" + radius + " A " + radius + " " + radius + " 1 " + mid + " 1 " + x + " " + y + " z";

        this.$piePath.attr("d", anim);

        this.$pie.css({
            transform: "rotateZ(" + (direction - fov / 2) + "deg)",
        });
    }
    getNavigation(horizontalAngleOfImage) {
        const NAVIGATION = VR360Config.VIEWER.NAVIGATION;

        const diameter = NAVIGATION.SIZE;
        const radius = diameter / 2;
        const circleRadius = radius - 4;
        const lineLength = 2 * Math.PI * circleRadius * horizontalAngleOfImage;
        const emptyLength = 2 * Math.PI * circleRadius * (1 - horizontalAngleOfImage);

        let svg =
            '<svg width="' + diameter + '" height="' + diameter + '" viewbox="0 0 ' + diameter + " " + diameter + '">';
        const bg =
            '<circle fill="#000" fill-opacity="' +
            NAVIGATION.OPACITY +
            '" cx="' +
            radius +
            '" cy="' +
            radius +
            '" r="' +
            radius +
            '" ></circle>';
        const bgLine =
            '<circle fill-opacity="0" cx="' +
            radius +
            '" cy="' +
            radius +
            '" r="' +
            circleRadius +
            '" stroke="#000"></circle>';
        const line =
            '<circle fill-opacity="0" cx="' +
            radius +
            '" cy="' +
            radius +
            '" r="' +
            circleRadius +
            '" ' +
            'stroke="#FFF" stroke-dasharray="' +
            lineLength +
            ", " +
            emptyLength +
            '" stroke-width="1" ' +
            'style="stroke-dashoffset:-' +
            ((2 * Math.PI * circleRadius) / 4 + emptyLength / 2) +
            '"></circle>';
        const triangle =
            '<path fill="#FFF" d="M' +
            (radius + 1.5) +
            " 4 L" +
            radius +
            " 1 L" +
            (radius - 1.5) +
            " 4 M" +
            (radius + 1.5) +
            ' 4 Z" ></path>';
        const svgEnd = "</svg>";

        if (horizontalAngleOfImage !== 1) {
            svg += bgLine;
        }

        svg += bg + line + triangle + svgEnd;

        return svg;
    }
}

export default VR360Navigation;
