let instanceStatus = {};

class ComponentInstanceManager {
    constructor() {
        if (!ComponentInstanceManager.instance) {
            ComponentInstanceManager.instance = this;
        }

        return ComponentInstanceManager.instance;
    }

    destroy() {
        for (const key in instanceStatus) {
            if (instanceStatus.hasOwnProperty(key)) {
                instanceStatus[key].instance.destroy && instanceStatus[key].instance.destroy();
                instanceStatus[key].instance.removeAllListeners();
                instanceStatus[key].instance = null;
                delete instanceStatus[key].instance;
                delete instanceStatus[key];
            }
        }
        instanceStatus = {};
    }

    getInstanceStatusById(compId) {
        return instanceStatus[compId];
    }

    setInstanceStatusById(compId, status) {
        instanceStatus[compId] = status;
    }

    getInstanceStatus() {
        return instanceStatus;
    }
}

export const componentInstanceManager = new ComponentInstanceManager();
