import _ from "lodash";

export const b2bDefaultConfig = config => {
    const videoPlayerType = _.get(config, "plugins.video.player.type");

    return {
        plugins: {
            map: {
                isDynamicMap: false,
                showBookmarkBtn: false,
                showBookingBtn: false,
                showDialBtn: false,
            },
            file: {
                downloadTypes: [
                    {
                        name: "local",
                        desc: "내 컴퓨터 저장",
                    },
                ],
            },
            video: {
                player: {
                    // custom player만 허용. 그외는 simple로 강제.
                    type: videoPlayerType === "custom" ? videoPlayerType : "simple",
                },
            },
            audio: {
                player: {
                    type: "simple"
                }
            }
        },
    };
};
