import _ from "lodash";

/**
 * 오디오 2.0 엔드뷰 재생 관리
 */
export class AudioPlayerManager {
    constructor(componentInstanceManager) {
        this._lastAudioInstance = null;

        this._componentInstanceManager = componentInstanceManager;

        this._audioComponentInstanceList = this._collectAudioComponentInstance();

        this._bindEvent();
    }

    _collectAudioComponentInstance() {
        const componentDataList = _.values(this._componentInstanceManager.getInstanceStatus());
        const audioComponentDataList = _.filter(componentDataList, componentData => {
            return componentData.compType === "v2_audio";
        });

        return _.map(audioComponentDataList, "instance");
    }

    _bindEvent() {
        this._audioPlayControl = this._audioPlayControl.bind(this);

        _.forEach(this._audioComponentInstanceList, audioComponentInstance => {
            audioComponentInstance.on("onPlay", this._audioPlayControl);
            audioComponentInstance.on("onResume", this._audioPlayControl);
        });
    }

    _audioPlayControl(instance) {
        const isSameAudio = instance.isSameAudio(this._lastAudioInstance) === true;
        const needLastAudioPause = this._lastAudioInstance && !isSameAudio;

        if (needLastAudioPause) {
            this._lastAudioInstance.pause();
        }

        this._lastAudioInstance = instance;
    }
}

export default AudioPlayerManager;
