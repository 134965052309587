/**
 * 360VR mobile용 config
 * @class se.module.v1.VR360.Config
 * @author woo jaewoo
 * @since 2016.10.12
 * PC와 클래스 이름이 동일
 */

export const VR360Config = {
    MSG: {
        INFO: "화면을 돌리거나 터치로 움직여보세요.",
        INFO_NO_GYRO: "화면을 터치로 움직여보세요.",
        NOT_SUPPORT:
            "현재 버전의 브라우저에서는 360VR 기능을 지원하지 않습니다.<br>최신 버전으로 업데이트 후 사용해주세요.",
    },
    PATH: {
        PREFIX: "PM-1.P-",
        VR360: "eq",
        PANORAMA: "pa",
    },
    VIEWER: {
        SRC_TYPE: {
            IN_CONTENTS: "cube1024",
            FULLSCREEN: "cube1024",
        },
        OPTION: {
            NORMAL: {
                controlMode: "yaw_pitch",
                fieldOfView: 65,
                yaw: 0,
                pitch: 0,
                projectionType: "vertical_cubestrip",
                allowPinchZoom: false,
                centerPolePoint: false,
            },
            FULLSCREEN: {
                controlMode: "yaw_pitch",
                fieldOfView: 65,
                yaw: 0,
                pitch: 0,
                projectionType: "vertical_cubestrip",
                allowPinchZoom: false,
                centerPolePoint: false,
            },
        },
        NAVIGATION: {
            SIZE: 33,
            PIE_RADIUS: 9,
            OPACITY: 0.6,
            HTML: {
                PIE: '<svg class="se_360vr_navPie __se_360vr_navPie" width="33px" height="33px" viewBox="0 0 33 33"><path fill="#FFF"></path></svg>',
            },
        },
        FULLSCREEN: {
            HTML: {
                CONTAINER: '<div class="se-360vr-fullscreen"></div>',
                TOGGLE_BUTTON: '<button class="se_btn_extend_toggle __se_360vr_fsToggle"></button>',
            },
        },
        PARALLAX: {
            ROTATE_RATIO: 0.03,
        },
    },
};
