/**
 * 360VR 뷰포트 내 스크롤 시 카메라 이동
 * @class se.module.v1.VR360.Parallax
 * @author woo jaewoo
 * @since 2016.11.15
 */

import {VR360Config} from "./vr360-config";
import {VR360ViewerMap} from "./vr360-viewer-map";

export const VR360Parallax = {
    init: function () {
        this.refScrollTop = null;
    },
    /**
     * 스크롤에 맞춰서 활성화 상태인 뷰어의 카메라 회전
     */
    rotateByScroll: function () {
        const rotateRatio = VR360Config.VIEWER.PARALLAX.ROTATE_RATIO;

        if (!this.refScrollTop) {
            this.refScrollTop = window.scrollY;
        }

        const scrollTopDelta = window.scrollY - this.refScrollTop;

        const visibleViewerElements = $seJq(".__se_360vr_container .__se_360vr_visible");
        $seJq.each(visibleViewerElements, function (index, element) {
            const key = element.__photo360__;
            if (!key) {
                return;
            }

            const viewer = VR360ViewerMap.getViewerByKey(key);
            if (viewer.isResumed()) {
                let yaw = viewer.getCurrentViewerDirection().yaw;
                yaw -= scrollTopDelta * rotateRatio;

                if (yaw > 180) {
                    yaw -= 360;
                } else if (yaw < -180) {
                    yaw += 360;
                }

                viewer.lookAt(
                    {
                        yaw: yaw,
                    },
                    100,
                );
            }
        });

        this.refScrollTop = window.scrollY;
    },
};

export default VR360Parallax;
