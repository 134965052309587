(function ($) {
    $.fn["checkLinkUse"] = function (forceImageLinkCursor) {
        // 이미지 링크 커서 강제 활성화 옵션이 있을 경우 체크 안함.
        if (forceImageLinkCursor) {
            return this;
        }

        this.each(function () {
            const $el = $seJq(this).closest("a");

            if (!$el || !$el.data("linkdata")) {
                return false;
            }

            const linkData = $el.data("linkdata");
            const isNotLink = linkData.linkUse === "false";

            if (isNotLink) {
                $el.removeAttr("href").attr("area-hidden", true);
            }
        });

        return this;
    };
})($seJq);
