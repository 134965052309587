import _ from "lodash";

import {Loader} from "./loader";
import {componentInstanceManager} from "./status/component-instance-manager";
import {componentLoadingStatus} from "./status/component-loading-status";
import {Parser} from "./common/parse";

/**
 * SE ONE Web SDK 지원 컴포넌트 유형입니다. 열거되지 않은 컴포넌트는 별도의 이벤트, 인터페이스를 제공하지 않는 컴포넌트입니다.
 * <br>&nbsp;&nbsp;
 * <br>&nbsp;&nbsp; - audio: 오디오 컴포넌트
 * <br>&nbsp;&nbsp; - code: 소스코드 컴포넌트
 * <br>&nbsp;&nbsp; - file: 파일 컴포넌트
 * <br>&nbsp;&nbsp; - formula: 수식 컴포넌트
 * <br>&nbsp;&nbsp; - image: 이미지 컴포넌트
 * <br>&nbsp;&nbsp; - vr360: 360º 이미지 컴포넌트
 * <br>&nbsp;&nbsp; - imageGroup: 그룹 이미지 컴포넌트
 * <br>&nbsp;&nbsp; - map: 장소 컴포넌트
 * <br>&nbsp;&nbsp; - material_shopping: 쇼핑 글감 컴포넌트
 * <br>&nbsp;&nbsp; - material_news: 뉴스 글감 컴포넌트
 * <br>&nbsp;&nbsp; - oembed: 오임베드 컴포넌트
 * <br>&nbsp;&nbsp; - oglink: 오지링크 컴포넌트
 * <br>&nbsp;&nbsp; - schedule: 일정 컴포넌트
 * <br>&nbsp;&nbsp; - table: 표 컴포넌트
 * <br>&nbsp;&nbsp; - video: 동영상 컴포넌트
 * @typedef {"audio" | "code" | "file" | "formula" | "image" | "vr360" | "imageGroup" | "map" | "material_shopping" | "material_news" | "oembed" | "oglink" | "schedule" | "table" | "video"} CompType
 */

/**
 * 레거시 규격으로 들어온 설정을 새로운 문서 뷰어 설정 구조에 맞게 재정의 하는 조각 코드
 * @ignore
 */
const redefineLegacySettings = config => {
    let transformedConfig = _.defaultsDeep({}, config, {
        productEnv: {},
        serviceConfig: {},
        productConfig: {},
        infraConfig: {},
        plugins: {},
        modules: {},
        events: {},
    });

    if (typeof config.DEVICE_TYPE !== "undefined") {
        transformedConfig.productEnv.deviceType = config.DEVICE_TYPE;
    }

    if (typeof config.VIEWER_TYPE !== "undefined") {
        transformedConfig.productEnv.viewerType = config.VIEWER_TYPE;
    }

    if (typeof config.RUN_ENV_TYPE !== "undefined") {
        transformedConfig.productEnv.buildEnv = config.RUN_ENV_TYPE;
    }

    if (typeof config.IMAGE_THUMBNAIL_TYPE !== "undefined") {
        transformedConfig.productConfig.imageThumbnailType = config.IMAGE_THUMBNAIL_TYPE;
    }

    if (typeof config.SERVICE_ID !== "undefined") {
        transformedConfig.serviceConfig.serviceId = config.SERVICE_ID;
    }

    if (typeof config.SERVICE_INAPP !== "undefined") {
        transformedConfig.serviceConfig.inApp = config.SERVICE_INAPP;
    }

    if (typeof config.fBeforeLoad !== "undefined") {
        transformedConfig.events.initialize = config.fBeforeLoad;
    }

    if (typeof config.fAfterLoad !== "undefined") {
        transformedConfig.events.ready = config.fAfterLoad;
    }

    if (typeof config.MODULE_INJECTION_DATA !== "undefined") {
        if (config.MODULE_INJECTION_DATA.VIDEO) {
            transformedConfig.plugins.video = {
                config: {
                    allowShareButton: {
                        hasLink: config.MODULE_INJECTION_DATA.VIDEO.ALLOW_SHARE_BUTTON_ALL.hasLink,
                    },
                },
            };
        }
    }

    if (typeof config.video !== "undefined") {
        transformedConfig.plugins.video = _.defaultsDeep({}, config.video, transformedConfig.plugins.video);
    }

    return transformedConfig;
};

export class AbstractViewer {
    constructor(config) {
        this._config = config;
        this._loader = new Loader();
    }

    __BEFORE_BOOTSTRAP__() {
        this.fBeforeLoad && this.fBeforeLoad();
    }

    __AFTER_BOOTSTRAP__() {
        this.fAfterLoad && this.fAfterLoad();
    }

    /**
     * 문서 뷰어를 초기화하고 실행합니다.
     * @method
     * @name SmartEditorViewer#bootstrap
     * @param options {DocumentViewerOptions} 문서 뷰어 옵션
     * @example
     * window.seViewer = new SmartEditorViewer();
     * seViewer.bootstrap({
     *     "productEnv": {
     *         ...
     *     },
     *     "serviceConfig": {
     *         ...
     *     },
     *     "productConfig": {
     *         ...
     *     },
     *     "plugins": {
     *         ...
     *     }
     * });
     */
    bootstrap(injectedConfig) {
        const redefinedConfig = redefineLegacySettings(injectedConfig);

        this.fBeforeLoad = redefinedConfig.events.initialize;
        this.fAfterLoad = redefinedConfig.events.ready;

        this.__BEFORE_BOOTSTRAP__(componentInstanceManager);

        const {dependencyLibraries, productConfig} = this._config;
        const config = _.defaultsDeep({}, redefinedConfig, productConfig);

        // 모든 컴포넌트 준비 완료되면 호출 되게 이벤트 바인딩
        componentLoadingStatus.updateCallback("allComponentReady", () => {
            this.__AFTER_BOOTSTRAP__(componentInstanceManager);
        });

        // 모든 컴포넌트 정상적으로 종료되지 않은 불안정한 상황에서도 콜백은 실행될 수 있도록 이벤트 바인딩
        componentLoadingStatus.updateCallback("unstableComponentReady", () => {
            this.__AFTER_BOOTSTRAP__(componentInstanceManager);
        });

        this._loader.initialize({dependencyLibraries, config});
    }

    /**
     * 지정한 컴포넌트 아이디의 컴포넌트 인스턴스를 반환합니다. 반환 받은 인스턴스를 이용해 해당 컴포넌트를 제어할 수 있습니다.
     * 지원하는 컴포넌트 유형은 {@link CompType}을 참고합니다.
     * @method
     * @name SmartEditorViewer#getInstance
     * @param compId {string} 컴포넌트 아이디
     * @returns {* | null} 컴포넌트 인스턴스
     * @example
     * seViewer.bootstrap({
     *     ...
     *     "events": {
     *         "ready": function() {
     *             // SE-efe66da8-ddbf-4326-973c-6eef6f883a82 아이디를 가진 동영상 컴포넌트 인스턴스 반환
     *             var videoCompInstance = seViewer.getInstance("SE-efe66da8-ddbf-4326-973c-6eef6f883a82");
     *         }
     *         ...
     *     }
     * });
     */
    getInstance(compId) {
        const status = componentInstanceManager.getInstanceStatusById(compId);

        if (status && status.instance) {
            return status.instance;
        }

        return null;
    }

    /**
     * 지정한 컴포넌트 타입의 컴포넌트 인스턴스 목록을 반환합니다.
     * 컴포넌트 타입을 지정하지 않으면 생성된 컴포넌트 인스턴스를 모두 반환합니다.
     * 지원하는 컴포넌트 유형은 {@link CompType}을 참고합니다.
     * @method
     * @name SmartEditorViewer#getModuleList
     * @param compType {?CompType} 컴포넌트 타입. SE3.0(v1)과 SE ONE(v2)을 구분해 이용해야 합니다.
     * @returns {*} 컴포넌트 인스턴스 배열
     * @example
     * seViewer.bootstrap({
     *     ...
     *     "events": {
     *         "ready": function() {
     *             // SE ONE 버전(v2)의 이미지 컴포넌트 목록 반환
     *             var imageCompInstances = seViewer.getModuleList("v2_image");
     *         }
     *         ...
     *     }
     * });
     */
    getModuleList(compType) {
        const status = componentInstanceManager.getInstanceStatus();
        const compList = [];

        if (!compType) {
            return _.values(status);
        }

        _.forIn(status, info => {
            if (info.compType === compType) {
                compList.push(info);
            }
        });

        return compList;
    }

    /**
     * 지정한 컴포넌트 타입의 컴포넌트 인스턴스에 이벤트를 할당합니다. 이 인터페이스를 이용해 서비스 별로 컴포넌트의 기능을 구현할 수 있습니다.
     * 단, EVENTS > ready 이벤트 발생 이후에 호출이 가능하며, 컴포넌트가 이벤트를 지원하는 경우만 이용할 수 있습니다.
     * 지원하는 컴포넌트 유형과 이벤트는 {@link AudioComponent}, {@link FileComponent}, {@link ShoppingComponent}, {@link NewsComponent}, {@link VideoComponent}를 참고합니다.
     * @method
     * @name SmartEditorViewer#onEventByType
     * @param compType {CompType} 컴포넌트 타입. SE3.0(v1)과 SE ONE(v2)을 구분해 이용해야 합니다.
     * @param customEvent {object} 컴포넌트 이벤트
     * @example
     * seViewer.bootstrap({
     *     ...
     *     "events": {
     *         "ready": function() {
     *             seViewer.onEventByType("v1_video", {
     *                 onLoadPlayer: function(data) {
     *                     console.log("onLoadPlayer");
     *                     console.log(data);
     *                 },
     *                 onClickLikeIt: function(data) {
     *                     console.log("onClickLikeIt");
     *                     console.log(data);
     *                 }
     *             });
     *
     *             seViewer.onEventByType("v2_video", {
     *                 onLoadPlayer: function(data) {
     *                     console.log("onLoadPlayer");
     *                     console.log(data);
     *                 },
     *                 onClickLikeIt: function(data) {
     *                     console.log("onClickLikeIt");
     *                     console.log(data);
     *                 },
     *                 onToggleVideoMetaInfo: function(data) {
     *                     console.log(data);
     *                 }
     *             });
     *
     *
     *             seViewer.onEventByType("v1_file", {
     *                 onClickDownload: function(payload) {
     *                     var type = payload.type;
     *                     var link = payload.link;
     *                     var event = payload.event;
     *
     *                     console.log('Click Download: ', type, link, event);
     *                     if (type === "local") {
     *                         location.href = link;
     *                     }
     *                 }
     *             });
     *
     *             seViewer.onEventByType("v2_file", {
     *                 onClickDownload: function(payload) {
     *                     var type = payload.type;
     *                     var link = payload.link;
     *                     var event = payload.event;
     *
     *                     console.log('Click Download: ', type, link, event);
     *                     if (type === "local") {
     *                         location.href = link;
     *                     }
     *                 }
     *             });
     *
     *             seViewer.onEventByType("v2_audio", {
     *                 onPlay: function(payload) {
     *                     console.log("audio play", payload);
     *                 },
     *                 onResume: function(payload) {
     *                     console.log("audio resume", payload);
     *                 },
     *                 onPause: function(payload) {
     *                     console.log("audio pause", payload);
     *                 }
     *             });
     *         }
     *         ...
     *     }
     * });
     */
    onEventByType(compType, customEvent) {
        const status = componentInstanceManager.getInstanceStatus();

        _.forIn(status, v => {
            const moduleType = v.compType;

            if (moduleType === compType && v.instance) {
                _.forIn(customEvent, (handler, eventName) => {
                    v.instance.on(eventName, handler);
                    v.event = customEvent;
                });
            }
        });
    }

    /**
     * 문서 뷰어의 모든 인스턴스와 이벤트를 해제합니다. 문서 뷰어를 종료하기 전에 반드시 호출합니다.
     * @method
     * @name SmartEditorViewer#destroy
     * @example
     * seViewer.destroy();
     */
    destroy() {
        /**
         * NOTE. SPA 형태의 서비스에서 jquery 로 DOM 에 건 이벤트 해제가 안되는 현상이 발생하여 초기화 진행
         * 다른 형태로 걸린 리스너는 해제되지 않는 것 확인하고 $seJq 를 통한 이벤트 해제를 일괄 진행
         * http://bts4.navercorp.com/nhnbts/browse/SEPLATFORM-1453
         * @ignore
         */
        $seJq(document).off();
        $seJq(window).off();

        componentInstanceManager.destroy();
        componentLoadingStatus.reset();
    }

    /**
     * 문서 뷰어를 리프레시합니다. 이미 초기화가 완료된 컴포넌트를 제외하고 초기화가 안된 컴포넌트만 초기화합니다.
     * @method
     * @name SmartEditorViewer#refresh
     */
    refresh() {
        this._loader.refresh();
    }

    /**
     * 링크 클릭 시 실행할 정보를 정의합니다.
     * @typedef {object} EventData
     * @property {boolean} isStop `preventDefault()` 실행 여부입니다.
     * @property {function} callback 클릭 이벤트 발생 시 호출할 콜백함수입니다.
     * @see onLinkResolver
     * @example
     * {
     *     isStop: true,
     *     callback: function(moduleData, event) {
     *         // moduleData.address: 장소 주소
     *         // moduleData.latitude: 위도
     *         // moduleData.longitude: 경도
     *         // moduleData.name: 장소 이름
     *         // moduleData.placeId: 장소 아이디
     *
     *         console.log(moduleData, event);
     *
     *         //서비스에서 URL 로 window.open
     *         window.open("https://news.naver.com/", "_blank");
     *     }
     * }
     */

    /**
     * 링크 클릭 시 실행할 정보를 가진 옵션입니다.
     * @typedef {object} LinkData
     * @property {EventData} map 장소 컴포넌트의 클릭 요소를 정의합니다.
     * @property {EventData} file 파일 컴포넌트의 클릭 요소를 정의합니다.
     * @property {EventData} img 이미지 계열(이미지 컴포넌트, 그룹 이미지 컴포넌트, 이미지 나란히 놓기 컴포넌트 등) 컴포넌트의 클릭 요소를 정의합니다.
     * @property {EventData} mediaTags 이미지 태그 버튼의 클릭 요소를 정의합니다.
     * @property {EventData} material 글감 컴포넌트의 클릭 요소를 정의합니다.
     * @see onLinkResolver
     * @example
     * {
     *     "map": {
     *         isStop: true,
     *         callback: function(moduleData, event) {
     *             // moduleData.address: 장소 주소
     *             // moduleData.latitude: 위도
     *             // moduleData.longitude: 경도
     *             // moduleData.name: 장소 이름
     *             // moduleData.placeId: 장소 아이디
     *
     *             console.log(moduleData, event);
     *
     *             //서비스에서 URL 로 window.open
     *             window.open("https://news.naver.com/", "_blank");
     *         }
     *     },
     *     "img": {
     *         isStop: true,
     *         callback: function(moduleData, event) {
     *             console.log(moduleData, event);
     *         }
     *     },
     *     "mediaTags": {
     *         isStop: true,
     *         callback: function(moduleData, event) {
     *             //console.log(moduleData, event);
     *             //moduleData: imgId, type, iconType, url, title, description
     *             if (moduleData.type === "image") {
     *                 window.open(moduleData.url);
     *             }
     *         }
     *     }
     * }
     */

    /**
     * 지정 요소의 링크 클릭 시 수행할 이벤트를 할당합니다. 서비스는 클릭 시 처리할 비즈니스 로직을 구현해야 합니다.
     * 단, EVENTS > ready 이벤트 발생 이후에 호출이 가능합니다.
     * @method
     * @name SmartEditorViewer#onLinkResolver
     * @param linkData {LinkData} 링크 클릭 시 실행할 정보
     * @example
     * seViewer.bootstrap({
     *     "events": {
     *         "ready": function() {
     *             seViewer.onLinkResolver({
     *                 "map": {
     *                     isStop: true,
     *                     callback: function(moduleData, event) {
     *                         // moduleData.address: 장소 주소
     *                         // moduleData.latitude: 위도
     *                         // moduleData.longitude: 경도
     *                         // moduleData.name: 장소 이름
     *                         // moduleData.placeId: 장소 아이디
     *
     *                         console.log(moduleData, event);
     *
     *                         //서비스에서 URL 로 window.open
     *                         window.open("https://news.naver.com/", "_blank");
     *                     }
     *                 },
     *                 "img": {
     *                     isStop: true,
     *                     callback: function(moduleData, event) {
     *                         console.log(moduleData, event);
     *                     }
     *                 },
     *                 "mediaTags": {
     *                     isStop: true,
     *                     callback: function(moduleData, event) {
     *                         //console.log(moduleData, event);
     *                         //moduleData: imgId, type, iconType, url, title, description
     *                         if (moduleData.type === "image") {
     *                             window.open(moduleData.url);
     *                         }
     *                     }
     *                 }
     *             });
     *         }
     *     }
     * });
     */
    onLinkResolver(linkData) {
        $seJq(document).on("click", ".__se_link", e => {
            let $el = $seJq(e.target);
            $el = $el.closest(".__se_link");

            const linkType = $el.attr("data-linktype");
            const data = $el.attr("data-linkdata");

            let eventSourceData;
            if (typeof data === "string") {
                eventSourceData = Parser.parseCompData(data);
            }

            let eventData;
            if (linkData && linkData[linkType]) {
                eventData = linkData[linkType];

                if (eventData.callback) {
                    if (eventData.isStop) {
                        e.preventDefault();
                    }
                }

                /*
                 * [SMRTEDITOR-10630]
                 * @TODO link element에 값이 담겨 있지 않아 __se_module_data를 참조하고 있습니다. // NOSONAR
                 * __se_module_data까지 찾아갈 필요 없이 link element에 값을 담아주도록 개선하면 이 코드는 제거해주세요.
                 * @see https://oss.navercorp.com/SE3/SE3-Server/blob/0c7471dee8ae7e7f4a6496fde34c2f1ae9364515/core/src/main/resources/mustache/document/component/map.mustache#L24
                 * */

                // [SMRTEDITOR-10684]
                // card 형의경우 searchType을 갖고 있는 $el.closest(".se_component").next(".__se_module_data") 가 없기때문에 직접 mustache에 추가
                // searchType이 없는 기본형의 경우에만 아래 로직에서 searchType을 가져옴

                if (linkType === "map" && eventSourceData.searchType === undefined) {
                    const moduleData = $el.closest(".se_component").next(".__se_module_data").data("module");

                    if (moduleData) {
                        eventSourceData.searchType = moduleData.data.searchType;
                    }
                }

                eventData.callback(eventSourceData, e);
            }
        });
    }

    /**
     * 본문, 소제목의 글자 크기를 변경합니다.
     * @method
     * @name SmartEditorViewer#changeDocFontSize
     * @param level {number} 폰트 레벨(0: 원본, n: n단계). 현재는 스펙 상 1 레벨만 제공합니다.
     */
    changeDocFontSize(level) {
        if (typeof level !== "undefined") {
            let $el = $seJq(".se-viewer");
            const isOneEditor = $el.length > 0;
            const fontSizeClass = this.assembleFontSize(isOneEditor, level);

            if (!isOneEditor) {
                $el = $seJq(".se_component_wrap");
            }

            this.removeFontSize($el);

            $el.addClass(fontSizeClass);
        }
    }

    assembleFontSize(isOneEditor, level) {
        const classPrefixSE3 = "se_fs_lv"; //se3.0 크게/작게 보기 클래스
        const classPrefixSEONE = "se-viewer-text-scale-"; //ONE에디터 크게/작게 보기 클래스
        let finalClass = "";

        if (level && level > 0) {
            finalClass = isOneEditor ? classPrefixSEONE : classPrefixSE3;
            finalClass = finalClass.concat(level);
        }

        return finalClass;
    }

    /**
     * 지정한 엘리먼트(jQuery)의 폰트 크기를 제거합니다.
     * @method
     * @name SmartEditorViewer#removeFontSize
     * @param $el {jQuery} 엘리먼트
     */
    removeFontSize($el) {
        const RegExpFontClassSE3 = /se_fs_lv[0-9]?/g;
        const RegExpFontClassSEONE = /se-viewer-text-scale-[0-9]?/g;
        let classListOfElement = $el.attr("class") || "";

        classListOfElement = classListOfElement.replace(RegExpFontClassSE3, "").replace(RegExpFontClassSEONE, "");

        $el.attr("class", classListOfElement.trim());
    }

    /**
     * 컴포넌트 초기화 완료, 또는 모든 컴포넌트 초기화 완료 후 처리할 이벤트를 등록합니다.
     * 단, EVENT > initialize 이벤트 발생 이후에 호출이 가능합니다.
     * @method
     * @name SmartEditorViewer#bindLoadCompEvent
     * @param compType {CompType | string} 이벤트를 등록할 컴포넌트 유형. {@link CompType} 중 `audio`, `code`, `file`, `formula`, `image`, `map`, `oembed`, `oglink`, `schedule`, `video` 컴포넌트 유형만 지원하며, `all` 값을 이용하면 모든 컴포넌트 초기화 완료 시 이벤트 핸들러를 실행할 수 있습니다.
     * @param callback {function} 이벤트 핸들러
     * @example
     * window.seViewer = new SmartEditorViewer();
     * seViewer.bootstrap({
     *     ...
     *     "events": {
     *         "initialize": function() {
     *             // 비디오 컴포넌트가 로드 완료 되면 실행
     *             seViewer.bindLoadCompEvent("video", function(){
     *                 console.log('video');
     *             });
     *
     *             // 모든 컴포넌트가 로드 완료 되면 실행
     *             seViewer.bindLoadCompEvent("all", function(){
     *                 console.log('all');
     *             });
     *         }
     *         ...
     *     }
     * });
     */
    bindLoadCompEvent(compType, callback) {
        componentLoadingStatus.updateCallback(compType, callback);
    }

    /**
     * 모든 오디오 플레이어를 일시 정지합니다.
     * @method
     * @name SmartEditorViewer#pauseAllAudioPlayer
     */
    pauseAllAudioPlayer() {
        const manager = window.$SE_AUDIOPLAYER_MANAGER;

        if (manager) {
            const player = manager.getPlayer();
            player && player.pause();
        }
    }
}

export default AbstractViewer;
