import _ from "lodash";
import EventEmitter from "events";
import Visible from "@egjs/visible";

class ComponentVisibleChecker extends EventEmitter {
    constructor() {
        super();

        this._visibleChecker = null;
        this._scrollTarget = null;

        this._bindEvent();
        this._initEgVisible();
    }

    destroy() {
        this._visibleChecker.off();
        $seJq(this._scrollTarget).off("scroll", this._handleScroll);
    }

    _bindEvent() {
        this._handleScroll = _.throttle(this._handleScroll.bind(this), 200);
        this._handleChangeVisible = this._handleChangeVisible.bind(this);
    }

    _initEgVisible() {
        this._scrollTarget = this._getScrollTarget();

        this._visibleChecker = new Visible(this._scrollTarget, {
            targetClass: "__se-component",
            expandSize: 0,
        }).on("change", this._handleChangeVisible);

        $seJq(this._scrollTarget).on("scroll", this._handleScroll);

        // 페이지 최조 진입시에는 스크롤 이벤트가 발생하지 않아서, visible.check()를 1회 수행하는데
        // 라이브러리 초기화 시점에 즉시 호출하면 체크가 잘 되지않아서 타임아웃을 설정함.
        setTimeout(() => {
            this._visibleChecker.check();
        }, 1000);
    }

    _handleScroll() {
        this._visibleChecker && this._visibleChecker.check();
    }

    _handleChangeVisible(event) {
        this._invisible(event.invisible);
        this._visible(event.visible);
    }

    _getScrollTarget() {
        return $seJq(".__scroll-target").get(0) || document;
    }

    _visible(elements) {
        _.forEach(elements, el => {
            // egjs/visible 라이브러리에서 visible/invisible 항목을 동시에 줄 때 중복되는 경우가 있음.
            // 그래서 invisible 선처리 후 visible 처리 되도록 타임아웃을 설정함.
            setTimeout(() => {
                this.emit("visible", el.id);
            }, 300);
        });
    }

    _invisible(elements) {
        _.forEach(elements, el => {
            this.emit("invisible", el.id);
        });
    }
}

export default ComponentVisibleChecker;
