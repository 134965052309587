/**
 * 페이지 내 360 뷰어 인스턴스 리스트
 * @class se.module.v1.VR360.ViewerMap
 * @author woo jaewoo
 * @since 2016.10.12
 */

export const VR360ViewerMap = (function () {
    const viewerMap = {};

    function getViewers() {
        return viewerMap;
    }

    function pushViewer(key, viewer) {
        viewerMap[key] = viewer;
    }

    function getViewerByKey(key) {
        if (!key) {
            return null;
        }
        return viewerMap[key];
    }
    function hasKey(key) {
        return viewerMap[key];
    }

    return {
        getViewers: getViewers,
        pushViewer: pushViewer,
        getViewerByKey: getViewerByKey,
        hasKey: hasKey,
    };
})();

export default VR360ViewerMap;
