/**
 * 제품의 CSS 정보입니다.
 */
import "./static/scss/se.viewer.scss";

/**
 * 제품의 코어 라이브러리입니다. 초기에 로딩이 필요한 것을 정의하며 se.viewer.js 파일로 묶입니다.
 * @ignore
 */
import "@libraries/jquery";
import "@libraries/eg.pkgd";

/**
 * 제품의 설정 정보입니다.
 * 이 설정은 사용자가 입력한 설정 정보와 머지되어 컴포넌트 인스턴스 생성 시 주입됩니다.
 * @ignore
 */
import productConfig from "./environments/product-config";

/**
 * 스마트에디터 뷰어 로더와 API입니다. 로드 순서를 위해 이곳에 위치하므로 수정하지 않습니다.
 * @ignore
 */
import {AbstractViewer, componentLoadingStatus, AudioPlayerManager} from "@se/viewer";

/**
 * 제품의 라이브러리입니다. 동적 로딩을 위해 별도로 정의하며 개별 파일로 생성됩니다.
 * @ignore
 */
const dependencyLibraries = [import("@libraries/hammerjs.compatible")];

/**
 * @class SmartEditorViewer
 * @classdesc SE ONE Web SDK 문서 뷰어 클래스입니다.
 */
class SmartEditorViewer extends AbstractViewer {
    constructor() {
        super({dependencyLibraries, productConfig});

        if (!SmartEditorViewer.instance) {
            SmartEditorViewer.instance = this;
        }

        return SmartEditorViewer.instance;
    }

    /**
     * 뷰어 모듈이 초기화 되기 전에 호출 되는 단계
     * 필요한 콜백을 등록하고 원하는 추가 작업을 할 수 있다.
     *
     * @param {Object} componentInstanceManager 각 뷰어 컴포넌트 인스턴스를 접근 할 수 있는 객체
     * @private
     * @ignore
     */
    __BEFORE_BOOTSTRAP__(componentInstanceManager) {
        super.__BEFORE_BOOTSTRAP__();
        componentLoadingStatus.updateCallback("audio", () => {
            new AudioPlayerManager(componentInstanceManager);
        });
        componentLoadingStatus.updateCallback("video", () => {
            // console.log("ready video");
        });
    }

    /**
     * 뷰어 모듈이 초기후 호출 되는 단계
     *
     * @param {Object} componentInstanceManager 각 뷰어 컴포넌트 인스턴스를 접근 할 수 있는 객체
     * @private
     * @ignore
     */
    __AFTER_BOOTSTRAP__() {
        super.__AFTER_BOOTSTRAP__();
    }
}

export default SmartEditorViewer;
