export default {
    productEnv: {
        deviceType: "desktop",
        viewerType: "normal",
        buildEnv: "real",
    },
    serviceConfig: {
        // 서비스마다 달라지는 설정
        serviceId: "demo",
        inApp: false,
        isInHouse: true,
    },
    productConfig: {
        // 문서 뷰어 전체에 영향 가는 설정
        // [SEPLATFORM-17542] 주요도메인정보
        domains: {
            dthumb: "dthumb-phinf.pstatic.net", // 동적썸네일생성API 도메인
        },
        imageThumbnailType: "w1",
    },
    plugins: {
        oglink: {
            youtubePlay: true,
        },
        video: {
            serviceId: {
                dev: 0,
                real: 0,
            },
            player: {
                type: "prism",
                contextmenu: false,
                controls: {
                    appLink: false,
                    title: false,
                    social: false,
                    like: false,
                    playCount: false,
                    likeCount: false,
                    help: false,
                    subtitle: false,
                    addSubtitle: false,
                },
            },
        },
        audio: {
            player: {
                type: "naver",
            },
        },
        file: {
            downloadTypes: [
                {
                    name: "local",
                    desc: "내 컴퓨터 저장",
                },
                {
                    name: "cloud",
                    desc: "네이버 MYBOX에 저장",
                },
            ],
        },
    }, // 컴포넌트 설정
    modules: {}, // 컴포넌트 외에 내/외부 모듈 설정
    events: {}, // 뷰어 이벤트 핸들러 등록
};
