(function ($) {
    $.fn["checkImageValid"] = function (loadEvent, errorEvent) {
        this.each(function () {
            const $each = $(this);
            const src = $each.attr("src");
            const img = new Image();

            img.onload = function () {
                $each.trigger(loadEvent);
            };

            img.onerror = function () {
                $each.trigger(errorEvent);
            };

            img.src = src;
        });

        return this;
    };
})($seJq);
