/**
 * @class se.module.v1.VR360.Viewer
 * @author woo jaewoo
 * @since 2016.10.12
 * Photo360Viewer + se.module.v1.VR360.Navigation
 */

import {VR360Navigation} from "./vr360-navigation";
import {VR360Config} from "./vr360-config";

export class VR360Viewer {
    constructor(key, $viewer, $nav, option, callbacks) {
        this.key = key;
        this.$viewer = $viewer;
        this.$nav = $nav;
        this.option = option;

        this._setKeyOnElement(key);

        this.photo360viewer = null;
        this.navigation = null;
        this.finalCallbacks = this._makeFinalCallbacks(callbacks);
    }

    getKey() {
        return this.key;
    }
    /**
     * 현재 바라보는 방향 정보를 객체로 리턴
     * @returns {{yaw: Number, pitch: Number, verticalFov: Number, horizontalFov: Number}}
     * @private
     */
    getCurrentViewerDirection() {
        const orientation = this.photo360viewer.getOrientation();
        const verticalFov = this.photo360viewer.getVerticalFieldOfView();
        const horizontalFov = this.photo360viewer.getHorizontalFieldOfView();
        return {
            yaw: orientation.yaw,
            pitch: orientation.pitch,
            verticalFov: verticalFov,
            horizontalFov: horizontalFov,
        };
    }
    /**
     * 뷰어가 정면 바라보게 하는 함수
     * 기본값 yaw: 0, pitch: 0, fov: 65
     */
    lookAtFront() {
        this.lookAt(
            {
                yaw: 0,
                pitch: 0,
                fieldOfView: 65,
            },
            500,
        );
    }
    /**
     * 뷰어 lookAt 래핑
     * @param option
     * @param duration
     */
    lookAt(option, duration) {
        this.photo360viewer.lookAt(option, duration);
    }
    /**
     * 뷰어가 활성화 상태인지 반환
     * @returns {boolean}
     */
    isResumed() {
        return this.photo360viewer && this.photo360viewer.isResumed;
    }
    /**
     * 뷰어에 photo360viewer 인스턴스가 없다면 생성
     * 뷰어 resume 래핑
     */
    resume() {
        if (!this.photo360viewer) {
            this.createPhoto360Viewer();
            return;
        }

        this.photo360viewer.resume();
    }
    /**
     * 뷰어 suspend 래핑
     */
    suspend() {
        if (!this.photo360viewer) {
            return;
        }

        this.photo360viewer.suspend();
    }
    /**
     * 뷰어의 updateViewportDimensions 래핑
     */
    updateViewportDimensions() {
        if (this.photo360viewer && this.photo360viewer.isResumed) {
            this.photo360viewer.updateViewportDimensions();
        }
    }
    /**
     * 뷰어의 루트 컨테이너 DOM을 반환
     * @returns {Object} viewer container jqeury object
     */
    getRootNode() {
        return this.$viewer;
    }
    /**
     * viewer setControlMode 래핑
     * @param controlMode
     * @param allowPinchZoom
     */
    setOptions(controlMode, allowPinchZoom) {
        this.photo360viewer.setControlMode(controlMode, allowPinchZoom);
    }
    /**
     * 현재 뷰어 방향에 맞춰 네비게이션 업데이트
     */
    updateNavigation() {
        if (this.photo360viewer && this.navigation) {
            const currentDirection = this.getCurrentViewerDirection();
            const yaw = currentDirection.yaw;
            const horizontalFov = currentDirection.horizontalFov;
            this.navigation.setState(yaw / -1, horizontalFov);
        }
    }
    startRender() {
        this.photo360viewer.startRender();
    }
    stopRender() {
        this.photo360viewer.stopRender();
    }
    /**
     * viewer container element에 key 값 배정
     * @param key
     * @private
     */
    _setKeyOnElement(key) {
        this.$viewer[0].__photo360__ = key;
    }
    /**
     * Photo360Viewer 객체 생성
     * @private
     */
    createPhoto360Viewer() {
        if (this._isPanorama()) {
            this._setPanoramaAngle(this._createPhoto360Viewer.bind(this));
            return;
        }

        this._createPhoto360Viewer();
    }
    _createPhoto360Viewer() {
        this.photo360viewer = new Photo360Viewer(this.$viewer[0], this.option);
        this._addViewerCallbacks();
        this.resume();
    }
    /**
     * Navigation 객체 생성
     * 현재 뷰어의 방향과 fov를 반영해서 생성
     * @private
     */
    _initNavigation() {
        if (!this.photo360viewer) {
            return;
        }

        const viewerDirection = this.getCurrentViewerDirection();
        const state = {
            direction: viewerDirection.yaw,
            fov: viewerDirection.horizontalFov,
            horizontalAngleOfImage: this.option.horizontalAngleOfImage,
        };

        this.navigation = new VR360Navigation(this.$nav[0], state);
        this.navigation.appendNavigation();
        this.navigation.resetState();

        this._addNavClickEvent();
    }
    /**
     * 전달받은 콜백을 default 콜백과 합쳐서 모든 이벤트에 대한 콜백 준비
     * @param callbacks
     * @returns {Object} 콜백 객체
     * @private
     */
    _makeFinalCallbacks(callbacks) {
        const defaultCallbacks = {
            resumestart: function () {},
            imageLoaded: function () {},
            init: function () {},
            resume: function () {},
            suspend: function () {},
            animationEnd: function () {},
            viewportChange: function () {},
            error: function () {},
        };
        return $seJq.extend({}, defaultCallbacks, callbacks);
    }
    /**
     * Photo360Viewer 이벤트에 맞춰 작동할 콜백 등록
     * @private
     */
    _addViewerCallbacks() {
        this.photo360viewer.on({
            resumestart: function (event) {
                this.finalCallbacks.resumestart(event);
            }.bind(this),
            imageLoaded: function (event) {
                this.finalCallbacks.imageLoaded(event);
            }.bind(this),
            init: function (event) {
                this._initNavigation();
                this.finalCallbacks.init(event);
            }.bind(this),
            resume: function (event) {
                this.finalCallbacks.resume(event);
            }.bind(this),
            suspend: function (event) {
                this.finalCallbacks.suspend(event);
            }.bind(this),
            animationEnd: function (event) {
                this.finalCallbacks.animationEnd(event);
            }.bind(this),
            viewportChange: function (event) {
                this.finalCallbacks.viewportChange(event);
                this.updateNavigation();
            }.bind(this),
            error: function (event) {
                this.finalCallbacks.error(event);
            }.bind(this),
        });
    }
    /**
     * Navigation 클릭하면 정면 바라보는 이벤트 등록
     * @private
     */
    _addNavClickEvent() {
        this.$nav.on("click", this.lookAtFront.bind(this));
    }

    initZoom() {
        this.photo360viewer.controlComposer.fieldOfView = 65;
        this.photo360viewer.controlComposer.verticalFieldOfView = 65;
    }

    _isPanorama() {
        const config = VR360Config;

        const regExp = new RegExp(config.PATH.PREFIX + "\\w+");
        const path = regExp.exec(this.option.image);

        if (path) {
            return config.PATH.PANORAMA === path[0].replace(config.PATH.PREFIX, "");
        }
        return false;
    }

    _setPanoramaAngle(callback) {
        const type = VR360Config.VIEWER.SRC_TYPE.IN_CONTENTS;
        const dummy = window.location.protocol.replace(":", "_") + window.location.hostname;
        const url = this.option.image.split("?")[0] + "?type=" + type + "&dummy=" + dummy;
        const self = this;
        const xhr = new XMLHttpRequest();

        xhr.open("GET", url, true);
        xhr.responseType = "arraybuffer";
        xhr.onload = function () {
            if (this.status == 200) {
                const imageArrayBuffer = this.response;
                const dataView = new DataView(imageArrayBuffer);
                const offset = 67;
                const count = 8;
                const values = [];
                let valueIdx = 0;
                for (; valueIdx < count; valueIdx++) {
                    values.push(dataView.getUint8(offset + valueIdx));
                }
                const angle = values
                    .map(function (v) {
                        return String.fromCharCode(v);
                    })
                    .join("")
                    .split("x")
                    .map(function (v) {
                        return parseInt(v, 10);
                    });

                self.option.horizontalAngleOfImage = (angle[0] * 360) / 100;
                self.option.verticalAngleOfImage = (angle[1] * 180) / 100;
                callback();
            }
        };
        xhr.send();
    }
}

export default VR360Viewer;
