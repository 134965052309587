import ImageController from "./imageController";
import VR360Manager from "../modules/vr360-manager";

export const ViewerLibBootstrap = (function () {
    function initImageController(config, i18n) {
        if (config.productEnv.viewerType.toLowerCase() === "normal" && ImageController) {
            const options = {
                target: [
                    {
                        key: "inline",
                        wrapper: ".se-inline-image",
                        className: "se-inline-image-resource",
                        klass: ".se-inline-image .se-inline-image-resource",
                    },
                    {
                        key: "image",
                        wrapper: ".se-module-image",
                        className: "se-image-resource",
                        klass: ".se-module-image .se-image-resource",
                    },
                    {
                        key: "material",
                        wrapper: ".se-material-thumbnail",
                        className: "se-material-thumbnail-resource",
                        klass: ".se-material-thumbnail .se-material-thumbnail-resource",
                    },
                    {
                        key: "sticker",
                        wrapper: ".se-module-sticker",
                        className: "se-sticker-image",
                        klass: ".se-sticker-image",
                    },
                    {
                        key: "se3",
                        className: "se_mediaImage __se_img_el",
                        klass: ".__se_img_el",
                        noImageFile: "https://editor-static.pstatic.net/c/resources/common/img/no_image.png",
                    },
                ],
                forceImageLinkCursor: config.productConfig.forceImageLinkCursor,
                noImageFileUrl: config.productConfig.noImageFileUrl,
                message: {
                    image_not_found: i18n("error:image_not_found"),
                    no_image: i18n("error:no_image"),
                    sticker_not_found: i18n("error:sticker_not_found"),
                },
            };

            ImageController.init(options);
        }
    }

    function initVR360Manager() {
        VR360Manager.init();
    }

    return {
        init: function ({config, moduleDataList, i18n}) {
            initImageController(config, i18n);

            const includesImageVR360 = moduleDataList.some(moduleData => {
                return ["v1_image360", "v2_image360"].includes(moduleData["type"]);
            });

            includesImageVR360 && initVR360Manager();
        },
    };
})();

export default ViewerLibBootstrap;
