/**
 * 360VR 전체화면 모듈
 * @class se.module.v1.VR360.FullScreen
 * @author woo jaewoo
 * @since 2016.10.12
 * 오픈소스 screenfull.js v3.0.2 사용
 */

import {Util} from "../../common/util";
import {default as screenfull} from "screenfull";

import {VR360Config} from "./vr360-config";
import {VR360ViewerMap} from "./vr360-viewer-map";

export const VR360FullScreen = {
    init: function (config) {
        this._appendFullscreenHTML();
        this.isFull = false;
        this._config = config;
    },

    addFullScreenEvent: function () {
        if (this._isScreenfullAvailable()) {
            screenfull.on("change", () => {
                if (!screenfull.isFullscreen) {
                    this._exitFullScreen();
                } else {
                    this._enterFullScreen();
                }
            });
        } else {
            $seJq(window).on("popstate", () => {
                this._exitFullScreen();
            });
        }
    },

    toggleFullScreen: function ($container, viewer) {
        if (this._isScreenfullAvailable()) {
            if (this.isFull) {
                screenfull.exit();
            } else {
                this.$originalContainer = $container;
                this.fullScreenViewer = viewer;

                screenfull.request();
            }
        } else if (this.isFull) {
            // [SMRTEDITOR-9193] 전체화면 이탈을 위해 history back
            // window popstate 이벤트에서 exit 처리
            window.history.back();
        } else {
            this.$originalContainer = $container;
            this.fullScreenViewer = viewer;

            // [SMRTEDITOR-9193] 전체화면 진입 시 history push
            if (!this._isScreenfullAvailable()) {
                window.history.pushState({}, "");
            }

            this._enterFullScreen();
        }
    },
    /**
     * 전체화면 진입
     */
    _enterFullScreen: function () {
        if (!this.fullScreenViewer) {
            return;
        }

        this.isFull = true;
        this.fullScreenViewer.stopRender();

        const $viewer = this.fullScreenViewer.getRootNode();
        $viewer.appendTo(this.$fullScreenContainer);

        $seJq("body").css("overflow", "hidden");
        this.$fullScreenContainer.addClass("se-is-on");

        this._moveFullScreenButton();
        this._suspendAllVisibleViewer();

        const option = $seJq.extend({}, VR360Config.VIEWER.OPTION.FULLSCREEN);
        this.fullScreenViewer.setOptions(option.controlMode, option.allowPinchZoom);

        if (!Util.isMobile()) {
            this._addScrollZoom(this.fullScreenViewer);
        }

        this.fullScreenViewer.updateViewportDimensions();
        this.fullScreenViewer.startRender();
    },

    /**
     * 전체화면 이탈 시 이벤트 off 및 스타일 정리
     * in-contents viewer 중 visible 뷰어 resume
     * @private
     */
    _exitFullScreen: function () {
        if (!this.isFull || !this.fullScreenViewer) {
            return;
        }

        this.isFull = false;
        this.fullScreenViewer.stopRender();

        this._resumeAllVisibleViewer();

        this.$fullScreenContainer
            .removeClass("se-is-on")
            .off("click", ".se-360vr-fullscreen-button")
            .off("mousewheel DOMMouseScroll");

        $seJq("body").css("overflow", "");

        this._moveFullScreenButton();

        this.$originalContainer.prepend(this.fullScreenViewer.getRootNode());

        const option = $seJq.extend({}, VR360Config.VIEWER.OPTION.NORMAL);
        this.fullScreenViewer.initZoom();
        this.fullScreenViewer.setOptions(option.controlMode, option.allowPinchZoom);
        this.fullScreenViewer.updateViewportDimensions();
        this.fullScreenViewer.startRender();

        // in-contents에서 정상적으로 보이도록 PC는 zoom 상태를 해제하고, mobile은 pinch를 0으로 맞춰준다.
        const lookAtOption = {
            fieldOfView: 65,
        };

        if (Util.isMobile()) {
            lookAtOption.pitch = 0;
        }

        this.fullScreenViewer.lookAt(lookAtOption, 100);
    },
    /**
     * 제일 처음 페이지 로드 했을 때 전체화면용 DOM을 append 한다
     * @private
     */
    _appendFullscreenHTML: function () {
        const seMainContainer = $seJq("div.se-main-container");
        this.$fullScreenContainer = seMainContainer.find(".se-360vr-fullscreen");

        if (this.$fullScreenContainer.length === 0) {
            seMainContainer.append(VR360Config.VIEWER.FULLSCREEN.HTML.CONTAINER);

            this.$fullScreenContainer = seMainContainer.find(".se-360vr-fullscreen");
        }
    },
    /**
     * 전체화면 API 사용가능 여부를 리턴한다
     * 브라우저 예외상황이 있다면 여기에 추가한다
     * @returns {boolean}
     * @private
     */
    _isScreenfullAvailable: function () {
        if (Util.isMobile()) {
            return false;
        }

        return screenfull.enabled;
    },
    /**
     * 스크롤 줌 추가 (PC 전체화면에서만 사용함)
     * @private
     */
    _addScrollZoom: function (fullScreenViewer) {
        this.$fullScreenContainer.on("mousewheel DOMMouseScroll", function (event) {
            event.preventDefault();
            const e = event.originalEvent;
            let delta = 0;
            if (e.detail) {
                delta = e.detail * -40;
            } else {
                delta = e.wheelDelta;
            }

            const fov = fullScreenViewer.getCurrentViewerDirection().verticalFov;
            fullScreenViewer.lookAt(
                {
                    fieldOfView: fov - delta / 10,
                },
                100,
            );
        });
    },
    /**
     * 전체화면 보기 버튼 위치 조정
     * @private
     */
    _moveFullScreenButton: function () {
        if (!Util.isMobile()) {
            return;
        }

        const btnVR360FullScreenToggle = this.$fullScreenContainer.find(".se-360vr-fullscreen-button");

        if (this.isFull) {
            btnVR360FullScreenToggle.css("top", "60px");
        } else {
            btnVR360FullScreenToggle.css("top", "12px");
        }
    },
    /**
     * 전체화면 진입을 위해서(성능 이슈) 활성화된 모든 뷰어 suspend
     */
    _suspendAllVisibleViewer: function () {
        const visibleViewerElements = $seJq(".__se_360vr_container .__se_360vr_visible");
        $seJq.each(
            visibleViewerElements,
            function (index, element) {
                const key = this._getViewerKeyByElement(element);
                const visibleViewer = this._getViewerByKey(key);
                visibleViewer.suspend();
            }.bind(this),
        );
    },
    /**
     * 전체화면 이탈 후 뷰포트 내 뷰어 활성화
     */
    _resumeAllVisibleViewer: function () {
        const visibleViewerElements = $seJq(".__se_360vr_container .__se_360vr_visible");
        $seJq.each(
            visibleViewerElements,
            function (index, element) {
                const key = this._getViewerKeyByElement(element);
                const visibleViewer = this._getViewerByKey(key);
                visibleViewer.resume();
            }.bind(this),
        );
    },
    /**
     * element에 속성으로 심은 뷰어 key
     * @param element
     * @returns {String}
     * @private
     */
    _getViewerKeyByElement: function (element) {
        if (!element) {
            return null;
        }
        return element.__photo360__;
    },
    /**
     * key 값으로 viewerMap에서 뷰어 가져오기
     * @param key
     * @returns {Object} viewer instance
     * @private
     */
    _getViewerByKey: function (key) {
        return VR360ViewerMap.getViewerByKey(key);
    },
};

export default VR360FullScreen;
