import EventEmitter from "events";
import {componentInstanceManager} from "./status/component-instance-manager";
import {componentLoadingStatus} from "./status/component-loading-status";

export class AbstractComponent extends EventEmitter {
    constructor(instanceStatus) {
        super();

        this.__status__ = 0;
        /**
         * Loader에서 인스턴스를 주입하던 로직이 타이밍 이슈로 __ON_READY_COMPONENT__ 선 호출 후 동작함에 따라
         * onEventByType 인터페이스를 이용해 이벤트가 걸리지 않는 이슈가 있어 우선 각 컴포넌트에서 인스턴스를 주입하도록
         * 수정합니다.
         * 이 이슈는 http://bts4.navercorp.com/nhnbts/browse/SEPLATFORM-782 엔드뷰 재설계 시 수정해야 합니다.
         */
        this.__instanceStatus__ = instanceStatus;

        // v1, v2 모델 버전과 관계없이 컴포넌트 wrapper 레벨에 __se-component 클래스 추가
        const $component = $seJq("#" + this.__instanceStatus__.id);
        $component && $component.addClass("__se-component");

        // 개발 버전에서만 인터페이스 체크 로직 호출
        if (process.env.DEBUG === true) {
            this.__checkComponentInterface();
        }
    }

    __ON_READY_COMPONENT__(componentType) {
        this.__instanceStatus__.instance = this;

        componentInstanceManager.setInstanceStatusById(this.__instanceStatus__.id, this.__instanceStatus__);
        componentLoadingStatus.loadComplete(componentType);

        this.__status__ = 1;
        delete this.__instanceStatus__;
    }

    __checkComponentInterface() {
        this.__checkCallOnReadyComponent();
    }

    __checkCallOnReadyComponent() {
        setTimeout(() => {
            if (this.__status__ !== 1) {
                throw new Error("Did you call the interface '__ON_READY_COMPONENT__' ?");
            }
        }, 5000);
    }

    hasBindEvent(eventName) {
        return this.listeners(eventName).length > 0;
    }
}

export default AbstractComponent;
