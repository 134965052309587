export const Parser = {
    parseCompData: sourceData => {
        /**
         * NOTE. oEmbed 컴포넌트 렌더링 시점에 parse 깨지는 경우가 발생하여 추가 보강
         * http://bts4.navercorp.com/nhnbts/browse/SEPLATFORMQA-105
         */
        return JSON.parse(sourceData.replace(/'/g, '\\"').replace(/(?:\r\n|\r|\n)/g, "<br />"));
    },

    parseVersion: version => {
        const versionSplit = version.split(".");
        const mainVersion = parseInt(versionSplit[0], 10);
        const subVersion = versionSplit[1] ? parseInt(versionSplit[1], 10) : 0;
        const thirdVersion = versionSplit[2] ? parseInt(versionSplit[2], 10) : 0;

        return {
            mainVersion: mainVersion,
            subVersion: subVersion,
            thirdVersion: thirdVersion,
        };
    },
};
