let loadingStatus = {};
let completeCallback = {};
let totalComponentCount = 0;

class ComponentLoadingStatus {
    constructor() {
        if (!ComponentLoadingStatus.instance) {
            ComponentLoadingStatus.instance = this;
        }

        this._timerCheckAllComponentReady = -1;
        this._checkAllComponentReady = this._checkAllComponentReady.bind(this);

        return ComponentLoadingStatus.instance;
    }

    reset() {
        loadingStatus = {};
        completeCallback = {};
        totalComponentCount = 0;
    }

    resetByRefresh() {
        loadingStatus = {};
        totalComponentCount = 0;
    }

    updateStatus(compName) {
        const isRegistered = completeCallback[compName];

        if (isRegistered) {
            let count = loadingStatus[compName];

            count = typeof count !== "number" ? 1 : count + 1;
            loadingStatus[compName] = count;
        }

        totalComponentCount++;

        /**
         * 근본적으로는 엔드뷰의 라이프 사이클 자체를 재정의 할 필요가 있긴 하나
         * 컴포넌트 리스트 중에 정상적으로 종료되지 않은 컴포넌트가 있어도 레디 이벤트에 등록된 콜백은 실행시켜줘야 하므로
         * 방어 차원에서 추가 대응 (http://bts4.navercorp.com/nhnbts/browse/SEPLATFORMQA-1598)
         */
        this._setTimerCheckAllComponentReady();
    }

    /**
     * 마지막 컴포넌트가 로딩 된 후로 일정 시간 동안 모든 컴포넌트 레디 이벤트가 발생했는지 체크하는 타이머를 등록하는 메소드
     *
     * @private
     */
    _setTimerCheckAllComponentReady() {
        // 리얼 서비스 환경에서 2초가 지나도록 컴포넌트 레디가 안된게 있으면 이슈 상황이라고 판단.
        const LIMIT_UNSTABLE_TIME = 2000;

        clearTimeout(this._timerCheckAllComponentReady);
        this._timerCheckAllComponentReady = setTimeout(this._checkAllComponentReady, LIMIT_UNSTABLE_TIME);
    }

    /**
     * 완전히 로드가 안된 컴포넌트를 체크해서 해당 컴포넌트가 있더라도
     * 종료 이벤트를 전달 받을 수 있도록 추가 이벤트 리스너를 발행하도록 처리
     *
     * @private
     */
    _checkAllComponentReady() {
        const notCompleted = this._getNotCompletedList();

        if (notCompleted.length > 0) {
            const unstableComponentReadyCallback = completeCallback.unstableComponentReady || [];
            unstableComponentReadyCallback[0] && unstableComponentReadyCallback[0]();
        }
    }

    updateCallback(compName, cb) {
        if (!completeCallback[compName]) {
            completeCallback[compName] = [cb];
        } else {
            completeCallback[compName] = [...completeCallback[compName], cb];
        }
    }

    loadComplete(compName) {
        /**
         * NOTE:
         * 기존에 만들어진 로딩 상태 체크 하는 로직이 콜백이 등록 되었을 경우만 all 이벤트가 발생하여
         * 'allComponentReady' 상태를 추가로 체크하게 변경.
         *
         * 향후 컴포넌트 로딩 라이프사이클을 재정리가 필요할 것으로 보여짐.
         */
        totalComponentCount--;

        // compName이 없을 때(loader의 _initializeComponent에서 컴포넌트가 없다고 판단하고 강제로 호출할 때)
        // 완료 콜백을 호출하도록 임시로 추가합니다.
        if (totalComponentCount === 0 || !compName) {
            const allComponentReadyCallback = completeCallback.allComponentReady || [];
            allComponentReadyCallback[0] && allComponentReadyCallback[0]();

            // 모든 컴포넌트 체크하는 로직 실행안되게 타이머 제거
            clearTimeout(this._timerCheckAllComponentReady);
        }

        let notCompleted = this._getNotCompletedList();

        if (notCompleted.length === 0) {
            return;
        }

        let count = loadingStatus[compName];

        if (typeof count === "number" && count > 0) {
            loadingStatus[compName] = --count;

            const callbacks = completeCallback[compName];
            if (count === 0 && callbacks) {
                callbacks.forEach(callback => {
                    callback();
                });
            }
        }

        notCompleted = this._getNotCompletedList();

        if (notCompleted.length === 0) {
            const callbacks = completeCallback.all;

            callbacks &&
                callbacks.forEach(callback => {
                    callback();
                });
        }
    }

    _getNotCompletedList() {
        return Object.keys(loadingStatus).filter(key => {
            return loadingStatus[key] > 0;
        });
    }
}

export const componentLoadingStatus = new ComponentLoadingStatus();
