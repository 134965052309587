import _ from "lodash";
import EventEmitter from "events";
import i18nNext from "i18next";

import {ViewerLibBootstrap} from "./lib/viewerLibBootstrap";
import {Parser} from "./common/parse";
import {componentLoadingStatus} from "./status/component-loading-status";
import {componentInstanceManager} from "./status/component-instance-manager";
import ComponentVisibleChecker from "./modules/visible-checker";
import defaultConfig from "./default-config";
import {Util} from "./common/util";
import {b2bDefaultConfig} from "./b2b-config";

function addResourceBundle(language, resources) {
    Object.keys(resources).forEach(namespace => {
        i18nNext.addResourceBundle(language, namespace, resources[namespace], true, false);
    });
}

export class Loader extends EventEmitter {
    constructor() {
        super();

        componentLoadingStatus.reset();
    }

    initialize(initFactor) {
        const {dependencyLibraries, config} = initFactor;
        const refinedConfig = this._refineConfig(config);
        const moduleDataList = this._extractModuleDataOnDocument();

        this._config = refinedConfig;

        this._initializeI18n(refinedConfig.productConfig.messageBundle || {}).then(() => {
            ViewerLibBootstrap.init({
                config: refinedConfig,
                moduleDataList,
                i18n: (...args) => {
                    return i18nNext.t(...args);
                },
            });

            setTimeout(() => {
                this._loadLibrariesOfProduct(dependencyLibraries, moduleDataList);
            }, 10);
        });

        this._visibleChecker = new ComponentVisibleChecker();

        this.emit("completeLoader");
    }

    refresh() {
        // 컴포넌트 로딩, 전체 로딩 완료 이벤트 전달을 위해 기존 상태를 초기화합니다.
        // 이벤트 콜백은 초기화하지 않습니다.
        componentLoadingStatus.resetByRefresh();

        const newModuleDataList = this._extractModuleDataOnDocument();
        this._initializeComponent(newModuleDataList);
    }

    get config() {
        return this._config;
    }

    _refineConfig(config) {
        if (!config.serviceConfig.isInHouse) {
            // B2B용 옵션을 고정한 뒤 나머지 옵션을 적용한다.
            return Util.defaultsDeepPreserveArrays({}, b2bDefaultConfig(config), config, defaultConfig);
        }
        return Util.defaultsDeepPreserveArrays({}, config, defaultConfig);
    }

    _initializeI18n(messageBundle) {
        const language = this._config.productConfig.language || "ko-KR";

        return new Promise(resolve => {
            i18nNext.init(
                _.defaultsDeep(
                    {},
                    {
                        lng: language,
                        debug: false,
                    },
                ),
                () => {
                    import(`./i18n/${language}.json`)
                        .then(resources => {
                            let mergedResources;

                            // [SEPLATFORM-32794] 서비스에서 문구 주입 가능하도록 설정
                            if (!_.isEmpty(messageBundle)) {
                                mergedResources = _.merge({}, resources, messageBundle);
                            }

                            addResourceBundle(language, mergedResources || resources);
                        })
                        .catch(() => {
                            console.debug && console.debug("[DEBUG] Not Found locale File.");
                        })
                        .finally(() => {
                            resolve();
                        });
                },
            );
        });
    }

    _loadLibrariesOfProduct(libs, moduleDataList) {
        if (libs.length > 0) {
            Promise.all(libs)
                .then(() => {
                    this.emit("completeLoadLibrary");
                    this._initializeComponent(moduleDataList);
                })
                .catch(() => {});
        } else {
            this.emit("completeLoadLibrary");
            this._initializeComponent(moduleDataList);
        }
    }

    _extractModuleDataOnDocument() {
        const $list = $seJq.find(".__se_module_data");
        const moduleDataList = [];

        $list.forEach(el => {
            let sourceData = $seJq(el).data("module");

            /**
             * 중요.
             * 서버에서 module data에 치환되지 않은 placeholder 키가 내려오게 되면 JSON Syntax 에러가 발생하는데
             * 다른 컴포넌트에 영향없이 해당 컴포넌트만 작동하지 않도록 방어 처리를 한다.
             */
            try {
                if (typeof sourceData === "string") {
                    sourceData = Parser.parseCompData(sourceData);
                }

                // 이미 생성된 컴포넌트는 필터링합니다.
                if (!componentInstanceManager.getInstanceStatusById(sourceData.id)) {
                    const {compName} = Loader.resolveCompData(sourceData);

                    componentLoadingStatus.updateStatus(compName);
                    moduleDataList.push(sourceData);
                }
            } catch (e) {
                // eslint-disable-next-line
                console.warn(`[ERROR] 정상적이지 않은 데이터가 감지 되었습니다.\n - data: ${sourceData}`);
            }
        });

        this.emit("completeExtractData", {moduleDataList});
        return moduleDataList;
    }

    _initializeComponent(moduleDataList) {
        if (!moduleDataList || moduleDataList.length === 0) {
            componentLoadingStatus.loadComplete();
        }

        moduleDataList.forEach(moduleData => {
            const {compType, compName} = Loader.resolveCompData(moduleData);
            const compInfo = this._resolveCompInfo(compType);

            if (compInfo) {
                import(
                    /* webpackChunkName: "component-" */
                    `@plugins/components/v${compInfo.version}/${compName}-component/src/index.js`
                )
                    .then(Component => {
                        // v1 기준 data, v2 기준 modelData, extraData
                        const {id, data = {}} = moduleData;
                        const componentConfig = _.defaultsDeep(
                            {},
                            this._getComponentConfigBy(compName),
                            compInfo.config,
                        );
                        const ComponentConstructor = Component.default;

                        data.id = id;

                        const config = {
                            // 표현 할 컴포넌트 데이터
                            initData: data,
                            // 뷰어 설정 전체
                            config: this._config,
                            // 컴포넌트의 설정
                            componentConfig,
                            // 다국어 처리를 위한 i18n 인스턴스
                            i18n: (...args) => {
                                return i18nNext.t(...args);
                            },
                            instanceStatus: {
                                docVersion: moduleData.docVersion,
                                compType,
                                data,
                                id,
                            },
                            visibleChecker: this._getVisibleChecker(),
                        };

                        return new ComponentConstructor(config);
                    })
                    .catch(err => {
                        // for Debug
                        console.debug && console.debug(`${compName.toUpperCase()}: ${err.message}`);
                    });
            } else {
                // for Debug
                console.debug && console.debug(`Failed to component info: ${compName.toUpperCase()} Component`);
            }
        });
    }

    _getComponentConfigBy(compName) {
        const componentConfigs = this._config.plugins;
        const componentConfig = componentConfigs[compName];

        if (componentConfig) {
            return componentConfig;
        }

        return null;
    }

    _getVisibleChecker() {
        return this._visibleChecker || {};
    }

    _resolveCompInfo(compType) {
        const target = _.find(this._config.components, o => {
            return o.type === compType;
        });

        if (!target) {
            return null;
        }

        return {
            version: target.version,
            config: target.config,
        };
    }

    _getNamespace(name) {
        return name.substr(0, 1).toLowerCase() + name.substr(1);
    }

    static resolveCompData(sourceData) {
        const compType = sourceData.type;
        const info = compType.split("_");
        const compVersion = info[0];
        const subDirectory = info[1];
        let compName = info[2];

        if (compName) {
            compName = `${subDirectory}/${compName}`;
        } else {
            compName = subDirectory;
        }

        return {
            compType,
            compVersion,
            compName,
        };
    }
}
