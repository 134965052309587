import _ from "lodash";

const RX_TEL = /^\+?\d[\d-]*$/;
const ALLOW_URL_VALUE_TYPE = ["string", "number", "boolean"];

export const Util = {
    isMobile: () => {
        const u = window.navigator.userAgent;
        const f = function (s, h) {
            return (h || "").indexOf(s) > -1;
        };

        return (
            f("Mobile", u) ||
            f("Android", u) ||
            f("Nokia", u) ||
            f("webOS", u) ||
            f("Opera Mini", u) ||
            f("BlackBerry", u) ||
            (f("Windows", u) && f("PPC", u)) ||
            f("Smartphone", u) ||
            f("IEMobile", u) ||
            f("iPad", u) ||
            f("iPhone", u)
        );
    },

    isIE8: () => {
        const browser = seEg.agent().browser;
        return browser.name === "ie" && parseInt(browser.version, 10) === 8;
    },

    isIEBelow9: () => {
        const browser = seEg.agent().browser;
        return browser.name === "ie" && parseInt(browser.version, 10) <= 9;
    },

    isIE10: () => {
        const browser = seEg.agent().browser;
        return browser.name === "ie" && parseInt(browser.version, 10) === 10;
    },

    isIEBelow10: () => {
        const browser = seEg.agent().browser;
        return browser.name === "ie" && parseInt(browser.version, 10) <= 10;
    },

    isIEBelow11: () => {
        const browser = seEg.agent().browser;
        return browser.name === "ie" && parseInt(browser.version, 10) <= 11;
    },

    isIE: () => {
        const u = window.navigator.userAgent.toLowerCase();
        return u.indexOf("trident") > -1 || u.indexOf("msie") > -1 || u.indexOf("edge") > -1;
    },

    isOldIE: () => {
        const u = window.navigator.userAgent.toLowerCase();
        const v = u.indexOf("msie") !== -1 ? parseInt(u.split("msie")[1]) : -1;
        return v > -1 && v <= 8;
    },

    isAndroid: () => {
        const agent = seEg.agent();
        return agent.os.name === "android";
    },

    isIOS: () => {
        const agent = seEg.agent();
        return agent.os.name === "ios";
    },

    loadScript: (url, options) => {
        const targetUrls = typeof url === "string" ? [url] : url;

        return Promise.all(
            targetUrls.map(targetUrl => {
                const _options = $seJq.extend(options || {}, {
                    dataType: "script",
                    cache: true,
                    url: targetUrl,
                });

                return $seJq.ajax(_options);
            }),
        );
    },

    debounce: (func, wait, immediate) => {
        let timeout;

        return function () {
            const self = this;
            const args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate) {
                    func.apply(self, args);
                }
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(self, args);
            }
        };
    },

    throttle: (func, wait, options) => {
        return _.throttle(func, wait, options);
    },

    get: (value, key, defaultValue) => {
        return _.get(value, key, defaultValue);
    },

    escape: str => {
        return _.escape(str);
    },

    classListRemove: (element, classes) => {
        const classList = classes.split(" ");
        const classListLength = classList.length;
        for (let i = 0; i < classListLength; i++) {
            element.classList.remove(classList[i]);
        }
    },

    /**
     * 문자열(sString) 내 7개의 특수문자(", ', &, <, >, (, ))를 <a href="http://www.w3schools.com/tags/ref_entities.asp" target="entitycode">HTML Entity Code</a>로 변경하여 반환
     * TODO: 에디터의 string-util.js 에서 가져옴. 먼 훗날 유틸함수들을 모듈화해서 lodash 처럼 cherry picking 하여 사용할 수 있는 날이 오길 기대해봅니다. // NOSONAR
     * @param    {String}    sString    원본 문자열
     * @returns    {String}            변경된 문자열
     * @see        restoreSpecialChar
     * @example
     * replaceSpecialChar() or replaceSpecialChar(123)
     * // 결과: ""
     *
     * replaceSpecialChar("&quot;, ', &, <, >, (, )")
     * // 결과: &amp;quot;, &amp;#39;, &amp;amp;, &amp;lt;, &amp;gt;, &#40;, &#41;
     */
    replaceSpecialChar(sString) {
        return typeof sString === "string"
            ? sString
                  .replace(/&/g, "&amp;")
                  .replace(/"/g, "&quot;")
                  .replace(/'/g, "&#39;")
                  .replace(/</g, "&lt;")
                  .replace(/>/g, "&gt;")
                  .replace(/\(/g, "&#40;")
                  .replace(/\)/g, "&#41;")
                  .replace(/\\u002F/g, "/")
            : "";
    },

    /**
     * 문자열(sString) 내 <a href="http://www.w3schools.com/tags/ref_entities.asp" target="entitycode">HTML Entity Code</a>로 된 특수문자 7개를 원래의 문자(", ', &, <, >, (, ))로 변경하여 반환
     * TODO: 에디터의 string-util.js 에서 가져옴. 먼 훗날 유틸함수들을 모듈화해서 lodash 처럼 cherry picking 하여 사용할 수 있는 날이 오길 기대해봅니다. // NOSONAR
     * @function
     * @param    {String}    sString    원본 문자열
     * @returns    {String}            변경된 문자열
     * @see        replaceSpecialChar
     * @example
     * restoreSpecialChar() or restoreSpecialChar(123)
     * // 결과: ""
     *
     * restoreSpecialChar("&amp;quot;, &amp;#39;, &amp;amp;, &amp;lt;, &amp;gt;,  &amp;#40;,  &amp;#41;")
     * // 결과: ", ', &, <, >, (, )
     */
    restoreSpecialChar(sString) {
        return typeof sString === "string"
            ? sString
                  .replace(/&amp;/g, "&")
                  .replace(/&quot;/g, '"')
                  .replace(/&#39;/g, "'")
                  .replace(/&lt;/g, "<")
                  .replace(/&gt;/g, ">")
                  .replace(/&#40;/g, "(")
                  .replace(/&#41;/g, ")")
                  .replace(/&apos;/g, "'")
                  .replace(/\//g, "\u002F")
            : "";
    },

    validateTel(str) {
        return RX_TEL.test(str);
    },

    /**
     * @private
     * @description {"key": "value"}, {"key": "value", "k": "v"} 구성 객체를 URL 파라메터로 변환
     * @param {Object} args 키와 값으로 구성된 객체 = default: {} / 사용 {"K": "V", "A", "B"}
     * @returns {string} encode 된 URL 파라메터
     */
    makeUrlParams(args = {}) {
        if (Array.isArray(args) || typeof args !== "object") {
            return "";
        }

        const makeParams = Object.entries(args)
            .filter(([key, value]) => typeof key === "string" && ALLOW_URL_VALUE_TYPE.includes(typeof value))
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

        return encodeURI(makeParams);
    },
    /**
     * 배열을 머지하지 않는 defaultsDeep
     * @param {...*} sources
     * @returns {*}
     */
    defaultsDeepPreserveArrays(...sources) {
        const output = {};

        sources.reverse();
        sources.forEach(source => {
            _.mergeWith(output, source, (objValue, srcValue) => {
                return Array.isArray(srcValue) ? srcValue : undefined;
            });
        });

        return output;
    },
};
