/**
 * 360VR 이미지 뷰어 모바일 매니저 static 모듈
 * @class se.module.v1.VR360.Manager
 * @author woo jaewoo
 * @since 2016.11.18
 */
import "@libraries/photo360viewer";

import {Util} from "../../common/util";
import {Parser} from "../../common/parse";

import {VR360Config} from "./vr360-config";
import {VR360FullScreen} from "./vr360-fullscreen";
import {VR360Parallax} from "./vr360-parallax";
import {VR360ViewerMap} from "./vr360-viewer-map";
import {VR360Viewer} from "./vr360-viewer";

export const VR360Manager = {
    init: function (config) {
        this.isVRSupport = this._checkVRSupport();
        this.CONFIG = VR360Config;

        VR360FullScreen.init(config);
        if (this.isVRSupport) {
            VR360Parallax.init();
            this._addEvents();
        }
    },
    createViewer(id, $viewer, $nav, option, callbacks) {
        return new VR360Viewer(id, $viewer, $nav, option, callbacks);
    },
    pushViewer(id, $viewer) {
        VR360ViewerMap.pushViewer(id, $viewer);
    },
    toggleFullScreen($section, viewer) {
        VR360FullScreen.toggleFullScreen($section, viewer);
    },
    getViewerByKey(key) {
        return VR360ViewerMap.getViewerByKey(key);
    },
    /**
     * 페이지 리사이즈와 스크롤 이벤트를 debounce와 throttle을 이용해서 등록
     * @private
     */
    _addEvents: function () {
        VR360FullScreen.addFullScreenEvent();

        $seJq(window)
            .on("resize", Util.debounce(this._onResizeWindow.bind(this), 100))
            .on("scroll", Util.debounce(this._onScrollDebounceVisible.bind(this), 100))
            .on("scroll", Util.throttle(this._onScrollThrottleParallax, 100));
    },
    _checkVRSupport: function () {
        const agent = seEg.agent();
        return this._checkWebGLSupport() && this._checkOSSupport(agent) && this._checkBrowserSupport(agent);
    },
    /**
     * webGL을 사용할 수 없으면 뷰어를 사용할 수 없다.
     * @private
     * @returns {bool}
     */
    _checkWebGLSupport: function () {
        return Photo360Viewer.isWebGLAvailable();
    },
    /**
     * OS 사용자 지정 제외
     * @param agent
     * @returns {boolean}
     * @private
     */
    _checkOSSupport: function () {
        return true;
    },
    /**
     * IE 11 미만은 성능이나 지원 불안정으로 사용자 지정 제외
     * @param agent
     * @returns {boolean}
     * @private
     */
    _checkBrowserSupport: function (agent) {
        const agentBrowser = agent.browser;
        let version;
        if (agentBrowser.name === "ie") {
            version = Parser.parseVersion(agentBrowser.version);
            if (version.mainVersion < 11) {
                return false;
            }
        }
        return true;
    },
    /**
     * window resize 시 viewer 영역에 맞춰 캔버스를 맞추고, navigation도 맞추기
     */
    _onResizeWindow: function () {
        // 리사이즈 시 뷰어 업데이트
        const viewerMap = VR360ViewerMap.getViewers();
        $seJq.each(viewerMap, function (key, viewer) {
            viewer.updateViewportDimensions();
            if (viewer.isResumed()) {
                viewer.updateNavigation();
            }
        });

        // 리사이즈 하다가 화면에 들어오는 경우 visible check
        // this.visible.check();
    },
    /**
     * 스크롤에서 debounce 이용한 visible check
     * @param event
     * @private
     */
    _onScrollDebounceVisible: function () {},
    /**
     * 스크롤에서 throttle을 이용한 시차 스크롤 적용
     * @private
     */
    _onScrollThrottleParallax: function () {
        VR360Parallax.rotateByScroll();
    },
};

export default VR360Manager;
