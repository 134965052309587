import "./jquery.checkImageValid";
import "./jquery.checkLinkUse";

export const ImageController = {
    init(options) {
        const LOAD_EVENT = "load";
        const ERROR_EVENT = "error";
        const {target, message} = options;
        const klasses = target.map(t => t.klass).join(",");
        const noImageFileUrl = options.noImageFileUrl || undefined;

        $seJq(klasses)
            .on(ERROR_EVENT, $seJq.proxy(this._onError, this, target, message, noImageFileUrl))
            .checkImageValid(LOAD_EVENT, ERROR_EVENT)
            .checkLinkUse(options.forceImageLinkCursor);
    },

    _onError(target, message, noImageFileUrl, event) {
        const $targetImage = $seJq(event.currentTarget);
        const className = $targetImage.attr("class");

        if (noImageFileUrl) {
            this._changeServiceNoImage($targetImage, noImageFileUrl);
            return;
        }

        const info = target.find(t => className && className.indexOf(t.className) > -1);

        if (!info) {
            this._change404Image({wrapper: $targetImage.parent()}, $targetImage);
            return;
        }

        const {key} = info;

        if (key === "inline") {
            this._change404Inline(info, $targetImage, message);
        } else if (key === "image") {
            this._change404Image(info, $targetImage, message);
        } else if (key === "material") {
            this._change404Meterial(info, $targetImage, message);
        } else if (key === "se3") {
            this._change404S3(info, $targetImage, message);
        } else if (key === "sticker") {
            this._change404Sticker(info, $targetImage, message);
        }
    },

    _changeServiceNoImage($targetImage, noImageFileUrl) {
        $targetImage && $targetImage.removeAttr("width height").attr("src", noImageFileUrl);
    },

    _change404Inline(info, $targetImage, message) {
        const {wrapper} = info;
        $targetImage.closest(wrapper).html(
            `<span class="se-state-error">
                <span class="se-state-error-detail">
                    <span class="se-state-error-text">${message.image_not_found}</span>
                </span>
            </span>`,
        );
    },

    _change404Sticker(info, $targetImage, message) {
        const {wrapper} = info;
        const $wrapper = $targetImage.closest(wrapper);

        $wrapper.html(
            `<div class="se-state-error">
                <div class="se-state-error-detail">
                    <div class="se-state-error-text">${message.sticker_not_found.replace("\n", "<br />")}</div>
                </div>
            </div>`,
        );
    },

    _change404Image(info, $targetImage, message) {
        const {wrapper} = info;
        const $wrapper = $targetImage.closest(wrapper);
        const width = $wrapper.parent().width();
        const size = this._getImageSizeStatus(width);
        const sizeClass = size ? `se-state-error-${size}` : "";
        const height = $wrapper.parent().height();
        const fitHeight = height > 151 ? `height: ${$wrapper.parent().height()}px` : ""; // 151은 높이가 없을 때 404 UI가 가질 수 있는 최소 높이

        $wrapper.html(
            `<div class="se-state-error ${sizeClass}" style="${fitHeight}">
                <div class="se-state-error-detail">
                    <div class="se-state-error-text">${message.image_not_found}</div>
                </div>
            </div>`,
        );
    },

    _change404Meterial(info, $targetImage, message) {
        const {wrapper} = info;
        const $wrapper = $targetImage.closest(wrapper);

        $wrapper.addClass("se-material-thumbnail-no-image");

        $wrapper.html(`<span class="se-material-thumbnail-no-image-text">${message.no_image}</span>`);
    },

    _change404S3(info, $targetImage) {
        const {noImageFile} = info; // SE3 용도의 noImageFile 이 따로 설정되어 있음
        $targetImage.removeAttr("width height").attr("src", noImageFile);
    },

    _getImageSizeStatus(width) {
        let status = "";

        if (width > 0 && width <= 100) {
            status = "tiny";
        } else if (width > 100 && width <= 300) {
            status = "small";
        }

        return status;
    },
};

export default ImageController;
